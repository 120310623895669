import React from 'react';
import Logo from '../../svg/logo_1.svg'

const LogoSVG = {
  marginTop: "35px",
  height: "auto",
  width: "175px",
}

const Footer = () => (
  <footer className="container mx-auto md:py-16 px-3 text-gray-800">
    {/* <div className="flex -mx-3">
      <div className="flex-1 ml-5">
        <h2 className="text-lg font-semibold">About Us</h2>
        <p className="mt-5">Marketsheets is based out of Ottawa, Canada started by two former Morgan Stanley analysts.</p>
      </div>
      <div className="block lg:flex-1 inline ml-48 px-3">
        <h2 className="text-lg font-semibold">Important Links</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="https://codebushi.com">Terms &amp; Conditions</a>
          </li>
          <li>
            <a href="https://codebushi.com">Privacy Policy</a>
          </li>
        </ul>
      </div>
      <div className="block lg:flex-1 px-3">
        <h2 className="text-lg font-semibold">Social Media</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="https://dev.to/changoman">Linkedin</a>
          </li>
          <li>
            <a href="https://twitter.com/HuntaroSan">Twitter</a>
          </li>
        </ul>
      </div>
    </div> */}
    <div className="container py-6">
      {/* <div className="flex justify-center mt-6">
          <div className="bg-white rounded-lg">
            <div className="flex flex-wrap justify-between md:flex-row">
              <input type="email" className="m-1 p-2 appearance-none text-gray-700 text-sm focus:outline-none" placeholder="Enter your email" />
              <button className="w-full m-1 p-2 text-sm bg-gray-800 rounded-lg font-semibold uppercase lg:w-auto">subscribe</button>
            </div>
          </div>
        </div> */}
      <hr className="h-px mt-6 bg-gray-400 border-none" />
      <div className="flex flex-col items-center justify-between mt-2 sm:mt-6 md:flex-row">
        <div className="hidden md:inline">
          <img style={LogoSVG} src={Logo} />
        </div>
        <div className="flex mt-2 md:mt-6 md:m-0">
          <div className="-mx-4 mt-4">
            <a href="https://www.linkedin.com/in/amarjasarbasic/" className="px-4 text-sm">About</a>
            <a href="https://twitter.com/marketsheetsapp" className="px-4 text-sm">Twitter</a>
            <a href="mailto:info@marketsheets.io" className="px-4 text-sm">Contact</a>
            <a href="/terms-of-service" className="px-4 text-sm">Terms of Service</a>
          </div>
        </div>
        <div className="md:hidden w-full mt-8">
          <img style={LogoSVG} className="mx-auto" src={Logo} />
        </div>
      </div>
    </div>

  </footer>
);

export default Footer;

import React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Logo from '../../svg/logo_1.svg'
import Button from '../Button';


const LogoContainer = {
  width: "200px",
  position: "relative",
  marginBottom: "18%",
}

const LogoSVG = {
  height: "auto",
  width: "100%",
  position: "absolute",
}

const Header = () => (
  <header className="sticky z-50 top-0 bg-white shadow">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        <div style={LogoContainer}>
          <AnchorLink to="/#home">
            <img style={LogoSVG} src={Logo} />
          </AnchorLink>
        </div>
      </div>
      <div className="flex px-4 mt-4 sm:mt-0">
        <AnchorLink className="px-4" to="/#howItWorks">
          Features
        </AnchorLink>
        <AnchorLink className="px-4" to="/#pricing">
          Pricing
        </AnchorLink>
        <AnchorLink className="px-4" to="/metrics">
          Metrics
        </AnchorLink>
        <AnchorLink className="px-4" to="/#beta">
          Beta
        </AnchorLink>
      </div>
      <div className="hidden md:block">
        <AnchorLink to="/#beta">
          <Button className="focus:outline-none text-sm">Access</Button>
        </AnchorLink>
      </div>
    </div>
  </header>
);

export default Header;